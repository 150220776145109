<template>
  <div>
    <!-- Hero div 2 -->
    <div id="hero-16" class="hero-section division" style="background-color: white; margin-top: 90px;">
      <!-- Added margin-top: 90px -->
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col-md-7 col-lg-6 order-first order-md-2">
            <div class="hero-16-txt">
              <h2 class="h2-sm" style="color: #065806;">Why Choose Miint Africa?</h2>
              <p class="p-xl">
                At Miint Africa, we empower businesses to thrive in the digital landscape. Our innovative solutions,
                expertise, and commitment to excellence set us apart.
              </p>
            </div>
          </div>
          <div class="col-md-5 col-lg-6 order-last order-md-2">
            <div class="hero-16-img pc-25 text-center">
              <img class="img-fluid" src="/images/products/photo-1387956632-612x612.jpg" alt="hero-image"
                style="border-radius: 10px;" />
            </div>
          </div>
        </div>
      </div>
    </div>

   <!-- Hero div 2 -->
    <div id="hero-16" class="hero-section division" style="background-color: #ffffff;">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col-md-12 text-center">
            <p class="h2-sm text-center custom-title">Why Miint Africa?</p>

            <p class="p-xl">Your Trusted Partner for Communication and Payment Solutions Across Africa</p>
            <p class="p-lg">
              At Miint Africa, we believe in providing value to our clients through our unique offerings. Here are a few
              reasons why you should choose us:
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Why Choose Us div -->
    <div id="why-choose-us" class="content-1 content-section division">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="feature-box">
              <i class="fas fa-sitemap" style="font-size: 36px; color: green;"></i>
              <h5 class="h5-md">Comprehensive Solutions</h5>
              <p>We provide a full suite of communication and payment solutions tailored for businesses, ensuring you have
                everything you need in one place.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="feature-box">
              <i class="fas fa-comments" style="font-size: 36px; color: green;"></i>
              <h5 class="h5-md">Customer-Centric Approach</h5>
              <p>Your satisfaction is our priority. We work closely with you to understand your needs and provide
                personalized solutions.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="feature-box">
              <i class="fas fa-lock" style="font-size: 36px; color: green;"></i>
              <h5 class="h5-md">Secure and Reliable</h5>
              <p>Our systems are built with the highest security standards to ensure your transactions and data are safe.
              </p>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 20px;">
          <div class="col-md-4">
            <div class="feature-box">
              <i class="fas fa-headset" style="font-size: 36px; color: green;"></i>
              <h5 class="h5-md">24/7 Support</h5>
              <p>We offer round-the-clock support to assist you with any inquiries or issues you may encounter.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="feature-box">
              <i class="fas fa-trophy" style="font-size: 36px; color: green;"></i>
              <h5 class="h5-md">Proven Track Record</h5>
              <p>With a history of successful partnerships, we have built a reputation for excellence in the industry.</p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="feature-box">
              <i class="fas fa-network-wired" style="font-size: 36px; color: green;"></i>
              <h5 class="h5-md">Pan-African Reach</h5>
              <p>We are committed to serving businesses across Africa, providing solutions that cater to diverse markets.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>




  </div>
</template>



<script>
export default {
  name: 'appWhyMiint',
};
</script>

<style scoped>
.custom-title {
  color: #048504;
  text-align: center;
  font-size: 2.5rem;
  /* Adjust size as needed */
  font-weight: bold;
  /* Makes the font stronger */
}
</style>
