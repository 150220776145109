<template>
  <!-- HERO-16 Section -->
  <div id="hero-16" class="hero-section division"
    style="background-color: #ffffff; position: relative; overflow: hidden; border: none; box-shadow: none; margin-top: -60px; height: 400px;">
    <!-- Background Image -->
    <div class="hero-background"
      style="background: url('images/home_bg.jpg') no-repeat center; background-size: cover; height: 1200px; width: 100%; position: absolute; top: 0; left: 0; z-index: 1;">
    </div>

    <!-- Overlay 
    <div class="overlay"
      style="background: rgba(255, 254, 254, 0.137); position: absolute; top: 0; left: 0; height: 100%; width: 100%; z-index: 2;">
    </div>-->

    <!-- Main Content -->
    <div class="container d-flex align-items-center justify-content-center"
      style="position: relative; z-index: 3; height: 100%; padding: 40px;">
      <div class="text-center" style="max-width: 800px; width: 100%;">
        <h2 class="h2-sm" style="color: #f9fff9; margin-bottom: 20px;">Aggregation services redefined</h2>
        <p style="color: #fff;">Get Streamlined access to telecom infrastructure, consume our SMS, USSD, Airtime, and
          Collections APIs and turn your ideas into reality.</p>

      </div>
    </div>
  </div>

  <!-- END HERO-16 Section -->

  <div id="content-1" class="content-1 content-section division" style="background-color: #ffffff;">
    <div class="container">
      <!-- Main Content -->
      <div class="container d-flex align-items-center justify-content-center"
        style="position: relative; z-index: 3; height: 100%; padding: 40px;">
        <div class="text-center" style="max-width: 800px; width: 100%;">


          <!-- Join the Waitlist Form -->
          <form style="background-color: rgba(255, 255, 255, 0.9); padding: 20px; border-radius: 8px;"
            @submit.prevent="submitPost()">
            <!-- Form Fields -->
            <div class="form-group mb-3">
              <input type="text" id="first_name" class="form-control" placeholder="Enter your first name" required
                v-model="first_name" style="height:45px;">
            </div>
            <p style="margin-top:-10px; text-align:left; font-size:13px;" class="text-muted">What is your first name</p>

            <div class="form-group mb-3">
              <input type="text" id="last_name" class="form-control" placeholder="Enter your last name" required
                v-model="last_name" style="height:45px;">
            </div>
            <p style="margin-top:-10px; text-align:left; font-size: 13px;" class="text-muted">What is your last name</p>

            <div class="form-group mb-3">
              <input type="email" id="email" class="form-control" placeholder="Enter your email" required v-model="email"
                style="height: 45px;">
            </div>
            <p style="margin-top:-10px; text-align:left; font-size: 13px;" class="text-muted">What is your email address
            </p>

            <div class="form-group mb-3">
              <input type="tel" id="phone" class="form-control" placeholder="Enter your phone number" required
                v-model="phone" style="height: 45px;">
            </div>
            <p style="margin-top:-10px; text-align:left; font-size: 13px;" class="text-muted">What is your mobile phone
              number</p>

            <div class="form-group mb-3">
              <input type="text" id="business" class="form-control" placeholder="Business Name" required
                v-model="business_name" style="height: 45px;">
            </div>
            <p style="margin-top:-10px; text-align:left; font-size: 13px;" class="text-muted">What is the name of your
              business</p>

            <div class="form-group mb-3">
              <select id="interest" class="form-control" required v-model="interest" style="height: 45px;">
                <option value="">Select Interest</option>
                <option value="sms">SMS</option>
                <option value="ussd">USSD</option>
                <option value="airtime">Airtime</option>
                <option value="collections">Collections</option>
                <option value="kyc">KYC Validation</option>
              </select>
            </div>
            <p style="margin-top:-10px; text-align:left; font-size: 13px;" class="text-muted">What service are you
              interested in</p>

            <div class="form-group mb-3">
              <input type="text" id="volumes" class="form-control" placeholder="Expected volumes" required
                v-model="expected_volumes" style="height: 45px;">
            </div>
            <p style="margin-top:-10px; text-align:left; font-size: 13px;" class="text-muted">What are your service
              expected volumes</p>

            <div class="form-group mb-3">
              <input type="text" id="pay" class="form-control" placeholder="How much do you expect to pay" required
                v-model="amount" style="height: 45px;">
              <p style="margin-top:1px; text-align:left; font-size: 13px;" class="text-muted">How much would you pay for
                these service</p>
            </div>

            <div class="form-group mb-3">
              <textarea id="details" class="form-control" rows="4"
                placeholder="Provide any additional information or requirements." style="resize: none;"
                v-model="additionalInfo"></textarea>
            </div>

            <button type="submit" class="btn btn-success btn-green btn-block">Submit</button>
          </form>

          <!-- Debugging -->
          <p v-if="debug">Form is being rendered</p>
          <hr>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
export default {
  name: 'JoinWaitingList',
  data() {
    return {
      to: "info@trueafrican.com",
      from: "notifications@trueafrican.com",
      subject: "Miint Africa Waitlist",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      expected_volumes: "",
      amount: "",
      business: "",
      emailBody: "",
      domain: "mg.trueafrican.com",
      dos: "71370e38123a4fa0fa65ea26cfbf4ef0-79295dd0-0f342aca",
      error_message: "",
      success_message: "",
      additionalInfo: "",
      username: "",
      showForm: false,  // Controls visibility of the form
      showPopup: false, // Controls visibility of the popup
    };
  },
  methods: {
    toggleForm() {
      this.showForm = !this.showForm;
      this.showPopup = !this.showPopup; // Toggle the popup when the form is shown
    },
    closePopup() {
      this.showPopup = false; // Close the popup when clicked
    },
    submitPost() {
      this.username = btoa(`api:${this.dos}`);
      this.buildEmail(this.first_name, this.last_name, this.phone, this.email, this.business, this.expected_volumes, this.interest, this.amount, this.additionalInfo);
      let formData = new FormData();
      formData.append('from', this.from);
      formData.append('to', this.to);
      formData.append('subject', this.subject);
      formData.append('text', this.emailBody);
      formData.append('html', this.emailBody);

      axios.post('https://api.mailgun.net/v3/' + this.domain + '/messages', formData,
        {
          headers: {
            "Authorization": "Basic " + this.username,
          },
        }).then(response => {
          if (response.status == 200) {
            this.first_name = "";
            this.last_name = "";
            this.phone = "";
            this.email = "";
            this.business = "";
            this.expected_volumes = "";
            this.interest = "";
            this.additionalInfo = "";
            this.success_message = "Request received successfully. We are going to get back to you shortly";

            // Redirect to the Thank You page
            window.location.href = '/thank-you';  // Update with the actual path to your thank you page
          }
        }).catch(error => {
          this.error_message = "Failed to submit request";
        })
    },
    buildEmail(firstName, lastName, phone, email, business, expectedVolumes, interest, amount, additionalInfo) {
      this.emailBody = 'Team, <br> <br> We have a new customer who has joined our waiting list. Below are their details for your reference: <br> First Name: ' + firstName + ' <br> Last Name: ' + lastName + '<br> Email: ' + email + ' <br> Phone Number: ' + phone + ' <br> Business Name: ' + business + ' <br> Interested Service: ' + interest + ' <br> Expected Volumes: ' + expectedVolumes + '<br> Amount: ' + amount + ' <br> Additional Information: ' + additionalInfo + ' Please ensure their information is properly recorded in our system and flagged for follow-up. If any team member needs additional details or has questions, let me know.';
    },
  },
  mounted() {
    $('.brands-carousel-6').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      autoplay: true,
      autoplayTimeout: 3000,
      responsive: {
        0: {
          items: 2
        },
        600: {
          items: 4
        },
        1000: {
          items: 6
        }
      }
    });

    $('.ussd-carousel').owlCarousel({
      loop: true,
      margin: 10,
      nav: false,
      autoplay: true,
      autoplayTimeout: 3000,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        1000: {
          items: 3
        }
      }
    });
  }
};
</script>


<style>
/* Hero Section Background */
.hero-background {
  background: url('@/assets/images/home_bg.jpg') no-repeat center center;
  background-size: cover;
  height: 100vh;
  /* Ensures the hero section covers the full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Overlay Effect */
.overlay {
  background: rgba(255, 254, 254, 0.137);
}

/* Form Container (Wrapper) */
.custom-form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  /* Adjust based on form height */
  padding: 20px;
}

.custom-form {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  /* Limit form width */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for form */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Hero Section Content */
.hero-background .content {
  text-align: center;
  color: white;
}

/* Form Controls Styling */
.form-control {
  margin-bottom: 15px;
  /* Add spacing between form fields */
}

.form-control:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

/* Form Select Styling */
.form-select {
  border: 1px solid #28a745;
  background-color: white;
  color: #495057;
  padding: 10px;
}

.form-select:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.form-select:hover {
  border-color: #218838;
}

.form-select option {
  background-color: white;
  color: #495057;
}

.form-select option:hover {
  background-color: #e9f5e9;
}

/* Placeholder Text */
::placeholder {
  color: #6c757d;
  opacity: 1;
}

/* Modal Header Close Button */
.modal-header .btn-close {
  background: #28a745;
  border-radius: 50%;
  opacity: 1;
}

.modal-header .btn-close:hover {
  background: #218838;
}

/* Submit Button Styling */
.btn-success {
  background-color: #28a745;
  border-color: #28a745;
  padding: 10px 20px;
  font-size: 16px;
  margin-top: 20px;
}

.btn-success:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

/* Floating Button Styling */
.floating-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 9999;
}

.floating-btn i {
  font-size: 24px;
}

.floating-btn:hover {
  background-color: #03290b;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .hero-background {
    padding: 20px;
  }

  .custom-form-wrapper {
    padding: 10px;
  }

  .custom-form {
    padding: 20px;
    width: 90%;
    /* Ensure form width adjusts on smaller screens */
  }

  .btn-success {
    width: 100%;
  }

  .floating-btn {
    bottom: 10px;
    right: 10px;
  }
}</style>

