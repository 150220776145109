<template>
  <!-- HERO-16 Section -->
  <div id="hero-16" class="hero-section division"
    style="background-color: #ffffff; position: relative; overflow: hidden; border: none; box-shadow: none; margin-top: -60px; height: 700px;">
    <!-- Background Image -->
    <div class="hero-background"
      style="background: url('images/home_bg.jpg') no-repeat center; background-size: cover; height: 1800px; width: 100%; position: absolute; top: 0; left: 0; z-index: 1;">
    </div>

    <!-- Overlay 
    <div class="overlay"
      style="background: rgba(255, 254, 254, 0.137); position: absolute; top: 0; left: 0; height: 100%; width: 100%; z-index: 2;">
    </div>-->

    <!-- Main Content -->
    <div class="container d-flex align-items-center justify-content-center"
      style="position: relative; z-index: 3; height: 100%; padding: 40px;">
      <div class="text-center" style="max-width: 800px; width: 100%;">
        <h2 class="h2-sm" style="color: #f9fff9; margin-bottom: 20px;">Aggregation services redefined</h2>
        <p style="color: #fff;">Get Streamlined access to telecom infrastructure, consume our SMS, USSD, Airtime, and Collections APIs and turn your ideas into reality.</p>
      <!-- Link to Join Waiting List -->
      <router-link to="/join-waiting-list" class="btn btn-success btn-green btn-block text-decoration-none">
        Join Waiting List
      </router-link>

      </div>
    </div>
  </div>


  <!-- END HERO-16 Section -->

  <div id="content-1" class="content-1 content-section division" style="background-color: #ffffff;">
    <div class="container">
      <div class="row d-flex align-items-left">
        <div class="col-md-7 col-lg-6 order-last order-md-2">
          <div class="txt-block left-column wow fadeInRight">
            <img src="/images/hero-48.jpg" />
          </div>
        </div>
        <div class="col-md-5 col-lg-6 order-first order-md-2 mt-10">
          <div class="row">
            <h3 class="h2-xs" style="text-align: left;">Simple, easy integrations</h3>
            <p class="p-lg justify-content-start" style="text-align: left">
              Miint Africa API allows you to seamlessly integrate telecom services into your business, helping you reach
              new heights. With our SMS, payment acceptance, and USSD APIs, you can transform your operations into a true
              powerhouse.
            </p>
            <ul style="text-align: left;">
              <li>1. Send SMS</li>
              <li>2. Accept payments through the Collections API</li>
              <li>3. Send Airtime</li>
              <li>4. KYC Validation for Phone numbers or National IDs</li>
              <li>5. Create USSD applications</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div id="content-1" class="content-1 content-section division" style="background-color: #ffffff;">
    <div class="container">
      <div class="row d-flex align-items-left">
        <div class="col-md-7 col-lg-6 order-last order-md-2">
          <div class="txt-block left-column wow fadeInRight">
            <h3 class="h2-xs" style="text-align: left;">Build custom aggregation experiences</h3>
            <p class="justify-content-start" style="text-align:left;">Developers appreciate well-documented, comprehensive
              APIs that enable them to create everything from simple hobby projects to sophisticated financial products
              serving hundreds of thousands of customers. If you can imagine it, you can build it with Miint Africa.</p>
            <ul style="text-align: left;">
              <li>1. JWT Authentication</li>
              <li>2. JSON Based API</li>
              <li>3. Check Status API</li>
            </ul>
          </div>
        </div>
        <div class="col-md-7 col-lg-6 order-last order-md-2">
          <div class="txt-block left-column wow fadeInRight">
            <img src="/images/hero_46.jpg" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="brands-1" class="pb-100 brands-section py-5">
    <div class="container">
      <!-- BRANDS TITLE -->
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-9">
          <div class="brands-title mb-50">
            <h5 class="s-17">Our partners</h5>
          </div>
        </div>
      </div>

      <!-- BRANDS CAROUSEL -->
      <div class="row" style="background-color: white;">
        <div class="col text-center">
          <div class="owl-carousel brands-carousel-6">
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/bank-of-africa.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/cafe-javas.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/dfcu.jpeg" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/gtbank.jpg" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/Absa_Logo_1.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/kcb.jpg" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/OpportunityBank.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/roke-telkom.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/Standard_Chartered.png" alt="brand-logo"></a>
            </div>
          </div>
        </div>
      </div> <!-- END BRANDS CAROUSEL -->
    </div> <!-- End container -->
  </div> <!-- END BRANDS-1 -->
  <div id="content-4" class="content-4 pt-40 content-section" style="background-color: #048504">
    <div class="bg-inner bg-04 pb-50 division">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col-md-5 col-lg-6 order-end order-md-2">
            <div class="content-4-img left-column wow fadeInRight mt-2">
              <img class="img-fluid" src="images/hero-49.jpg" alt="content-image">
            </div>
          </div>
          <div class="col-md-7 col-lg-6 order-first order-md-2">
            <div class="txt-block right-column white-color wow fadeInLeft">
              <h2 class="h2-xs" style="text-align:left;">Ready to get started?</h2>
              <p class="p-lg" style="text-align:left;">Create an account and instantly start building amazing apps and
                selling your goods and
                products online.</p>
              <a href="https://dashboard.miintafrica.com/register" class="btn btn-white tra-grey-hover"
                style="margin-top: 10px;">Get Started</a>
            </div>
          </div> <!-- END CONTENT TXT -->


        </div> <!-- End row -->
      </div> <!-- End container -->
    </div> <!-- End Inner Background -->
    <!-- Floating Button to open KYC modal -->
  </div> <!-- END CONTENT-4 -->
</template>
<script>
/* eslint-disable */
export default {
  name: 'appHome',
  
  methods: {
    redirectToJoinWaitingList() {
      // Perform any necessary validation or processing here
      if (this.first_name && this.last_name && this.email && this.phone) {
        // Redirect to JoinWaitingList.vue page after validation
        this.$router.push({ name: 'joinwaitinglist' }); // Ensure you have the correct route name in your router
      } else {
        this.error_message = "Please fill in all the required fields before submitting.";
      }
    },
  
  },
  mounted() {
    $('.brands-carousel-6').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      autoplay: true,
      autoplayTimeout: 3000,
      responsive: {
        0: {
          items: 2
        },
        600: {
          items: 4
        },
        1000: {
          items: 6
        }
      }
    });

    $('.ussd-carousel').owlCarousel({
      loop: true,
      margin: 10,
      nav: false,
      autoplay: true,
      autoplayTimeout: 3000,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        1000: {
          items: 3
        }
      }
    });
  }
};
</script>

<style>
/* Hero Section Background */
.hero-background {
  background: url('@/assets/images/home_bg.jpg') no-repeat center center;
  background-size: cover;
}

/* Overlay Effect */
.overlay {
  background: rgba(255, 254, 254, 0.137);
}

/* Feature Box Icon Styling */
.fbox-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

/* Floating Button Styling */
.floating-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #28a745; /* Green theme */
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px; /* Adjusted font size */
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 9999;
}

.floating-btn i {
  font-size: 24px; /* Icon size */
}

.floating-btn:hover {
  background-color: #03290b; /* Darker green */
}

/* Dropdown Styling */
.form-select {
  border: 1px solid #28a745; /* Green border */
  background-color: white;
  color: #495057; /* Text color */
}

.form-select:focus {
  border-color: #28a745;
  /* Green border */
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  /* Green glow */
}

.form-select:hover {
  border-color: #218838; /* Darker green hover border */
}

.form-select option {
  background-color: white;
  color: #495057;
}

.form-select option:hover {
  background-color: #e9f5e9; /* Light green */
}

/* Form Controls Styling */
.form-control:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.custom-form {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 8px;
}

/* Placeholder Text */
::placeholder {
  color: #6c757d;
  /* Subtle gray placeholder text */
  opacity: 1;
}

/* Modal Header Close Button */
.modal-header .btn-close {
  background: #28a745;
  /* Green background */
  border-radius: 50%;
  opacity: 1;
}

.modal-header .btn-close:hover {
  background: #218838;
  /* Darker green */
}

/* Submit Button Styling */
.btn-success {
  background-color: #28a745;
  /* Consistent green */
  border-color: #28a745;
}

.btn-success:hover {
  background-color: #218838;
  /* Darker green on hover */
  border-color: #1e7e34;
}
</style>

