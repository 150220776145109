<template>
  <header :class="['header', 'tra-menu', 'navbar-dark', { 'scrolled': isScrolled }]">
    <div class="header-wrapper">
<!-- Mobile Header -->
<div
  class="wsmobileheader d-flex justify-content-between align-items-center d-md-none"
>
  <span class="smllogo">
    <router-link to="/" class="logo-black">
      <img :src="logo" alt="header-logo" />
    </router-link>
  </span>
  <button
    class="navbar-toggler"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#navbarNav"
    aria-controls="navbarNav"
    aria-expanded="false"
    aria-label="Toggle navigation"
    @click="toggleMobileMenu"
    style="background-color: #048504; border: none; padding: 8px; width: 40px; height: 40px; display: flex; justify-content: center; align-items: center; position: absolute; right: 20px; top: 50%; transform: translateY(-50%); z-index: 10;"
  >
    <span 
      class="navbar-toggler-icon" 
      style="background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox=%270%200%2030%2030%27%20xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath%20stroke=%27white%27%20stroke-width=%272%27%20stroke-linecap=%27round%27%20stroke-miterlimit=%2710%27%20d=%27M4%207h22M4%2015h22M4%2023h22%27/%3E%3C/svg%3E');"></span>
  </button>
</div>

<!-- Mobile Menu -->
<div
  :class="['mobile-menu', { 'mobile-menu-open': isMobileMenuOpen }]"
  class="d-md-none"
>
  <ul class="mobile-menu-list">
    <li
      @click="toggleDropdown('products')"
      :class="{ active: activeDropdown === 'products' }"
    >
      <span class="dropdown-toggle">
        Products<span :class="{ open: activeDropdown === 'products' }"></span>
      </span>
      <ul v-if="activeDropdown === 'products'" class="sub-menu">
        <li><router-link to="/sms" @click="closeMobileMenuAndScroll">SMS</router-link></li>
        <li><router-link to="/collections" @click="closeMobileMenuAndScroll">Collections</router-link></li>
        <li><router-link to="/airtime" @click="closeMobileMenuAndScroll">Airtime</router-link></li>
        <li><router-link to="/kyc-validation" @click="closeMobileMenuAndScroll">KYC Validation</router-link></li>
        <li><router-link to="/ussd" @click="closeMobileMenuAndScroll">USSD ShortCode Rental</router-link></li>
      </ul>
    </li>
    <li><router-link to="/why-miint" @click="closeMobileMenuAndScroll">Why Miint</router-link></li>
    <li><router-link to="/prices" @click="closeMobileMenuAndScroll">Prices</router-link></li>
    <li>
      <a href="https://miint-africa.gitbook.io/docs" target="_blank" @click="closeMobileMenu">Developers</a>
    </li>
  </ul>
</div>


      <!-- Desktop Menu -->
      <div class="wsmainfull menu clearfix d-none d-md-block">
        <div class="wsmainwp clearfix">
          <div class="desktoplogo">
            <router-link to="/" class="logo-black">
              <img :src="logo" alt="header-logo" />
            </router-link>
          </div>
          <nav class="wsmenu clearfix">
            <ul class="wsmenu-list nav-green-hover">
              <li aria-haspopup="true" class="mg_link">
                <a href="#" :class="{ 'white-text': !isScrolled }">Products<span class="wsarrow"></span></a>
                <ul class="sub-menu">
                  <li><router-link to="/sms" @click="closeMobileMenuAndScroll">SMS</router-link></li>
                  <li><router-link to="/collections" @click="closeMobileMenuAndScroll">Collections</router-link></li>
                  <li><router-link to="/airtime" @click="closeMobileMenuAndScroll">Airtime</router-link></li>
                  <li><router-link to="/kyc-validation" @click="closeMobileMenuAndScroll">KYC Validation</router-link></li>
                  <li><router-link to="/ussd" @click="closeMobileMenuAndScroll">USSD ShortCode Rental</router-link></li>
                </ul>
              </li>
              <li>
                <router-link to="/why-miint" @click="closeMobileMenuAndScroll" :class="{ 'white-text': !isScrolled }">Why Miint</router-link>
              </li>
              <li>
                <router-link to="/prices" @click="closeMobileMenuAndScroll" :class="{ 'white-text': !isScrolled }">Prices</router-link>
              </li>
              <li>
                <a href="https://miint-africa.gitbook.io/docs" target="_blank" @click="closeMobileMenuAndScroll" :class="{ 'white-text': !isScrolled }">Developers</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "appHeader",
  data() {
    return {
      logo: "/images/mint_logo_3.png",
      isScrolled: false,
      isMobileMenuOpen: false,
      activeDropdown: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.$router.afterEach(() => {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false;
      this.activeDropdown = null;
    },
    closeMobileMenuAndScroll() {
      this.closeMobileMenu();
      this.$nextTick(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    },
    toggleDropdown(menu) {
      this.activeDropdown = this.activeDropdown === menu ? null : menu;
    },
  },
};
</script>

<style>
.header-wrapper {
  transition: background-color 0.3s;
}

.header.scrolled .header-wrapper {
  background-color: white;
}

.wsmobileheader .smllogo img {
  max-width: 120px;
}

.desktoplogo img {
  max-width: 150px;
}

.wsmenu-list {
  display: flex;
  list-style: none;
  padding-left: 0;
}

.wsmenu-list li {
  margin-right: 20px;
}

/* Default text color for links */
.wsmenu-list li a {
  color: #ffffff;
  /* White color before scrolling */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
  /* Smooth color transition */
}

/* Change text color on hover */
.wsmenu-list li a:hover {
  color: #048504;
  /* Green on hover */
}

/* Change text color when scrolled */
.header.scrolled .wsmenu-list li a {
  color: #048504;
  /* Green color when scrolled */
}

/* Specific items to be white in font color */
.white-text {
  color: #ffffff !important;
  /* White color before scrolling */
}

/* Specific items to be green in font color */
.green-text {
  color: #048504 !important;
  /* Green color after scrolling */
}

/* Additional styles for better visibility */
.header.scrolled .wsmenu-list li a:hover {
  color: #0a7d0a;
  /* Darker green on hover when scrolled */
}

/* Button text color */
.btn-tra-green {
  color: #ffffff !important;
  /* White text color for the button */
}

/* Ensure Sign In button is styled correctly */
.btn-tra-green.green-text {
  color: #048504 !important;
  /* Green text when scrolled */
}

 
/* Navbar Toggler Styles */
.navbar-toggler {
  border: none;
  background-color: #048504; /* Green background */
  border-radius: 5px; /* Rounded corners for a better look */
  padding: 8px; /* Adjust padding for better size */
  width: 40px; /* Set a fixed width */
  height: 40px; /* Set a fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  position: absolute; /* Position relative to the header */
  right: 20px; /* Push the button away from the edge */
  top: 50%; /* Vertically center the button */
  transform: translateY(-50%); /* Align it perfectly */
  z-index: 10; /* Ensure the toggler button stays on top */
}


/* Logo Positioning */
.header-logo {
  position: absolute;
  left: 20px; /* Adjust as needed */
  top: 50%; /* Vertically center */
  transform: translateY(-50%); /* Align logo perfectly */
}

/* Mobile Menu Styling */
.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 9999;
  padding: 20px;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.mobile-menu-open {
  transform: translateX(0);
}

.mobile-menu-list {
  list-style: none;
  padding: 0;
}

.mobile-menu-list li {
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1;
}

.mobile-menu-list li a {
  color: #333;
  font-size: 18px;
  text-decoration: none;
}

.mobile-menu-list li a:hover {
  color: #048504;
}

.sub-menu {
  padding-left: 20px;
}

.wsarrow {
  margin-left: 10px;
  transition: transform 0.3s;
}

.sub-menu .wsarrow {
  transform: rotate(90deg);
}

.sub-menu li a {
  font-size: 16px;
  font-weight: normal;
}

/* Navbar Toggler Styles */
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
@media (max-width: 767px) {
  .wsmainfull {
    display: none;
  }

  /* Ensure logo and mobile menu toggler don't overlap */
  .header-wrapper {
    position: relative;
    padding-top: 20px; /* Space at the top to prevent overlap */
  }
  
  .wsmobileheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  .smllogo {
    order: 1;
  }
  
  .navbar-toggler {
    order: 2;
    position: relative;
    z-index: 10; /* Ensure toggler is above other elements */
  }

  /* Fix mobile menu positioning with more space at the top */
  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    z-index: 9999;
    padding-top: 80px; /* Increased padding to give more space at the top */
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .mobile-menu-open {
    transform: translateX(0);
  }
}

</style>