<template>
  <!-- HERO-16
    ============================================= -->
  <section id="hero-16" class="hero-section division" style="background-color: white; margin-top: 90px;">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-md-5 col-lg-6 order-last order-md-2">
          <div class="hero-16-img pc-25 text-center">
            <img class="img-fluid" src="images/hero-image1.png" alt="hero-image" style="border-radius:10px;">
          </div>
        </div>
        <div class="col-md-7 col-lg-6 order-first order-md-2">
          <div class="hero-16-txt">
            <h2 class="h2-sm" style="color:#065806;">Empowering Aggregation Solutions Across Africa for Africa</h2>
            <p class="p-xl">Get Streamlined access to telecom infrastructure, consume our SMS, USSD,
              Airtime, and Collections APIs and turn your ideas into reality
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div id="brands-2" class="wide-70 brands-section division">
    <div class="container">
      <!-- BRANDS TITLE -->
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="brands-title text-center">
            <p class="p-xl">Some of our partners</p>
          </div>
        </div>
      </div>
      <!-- BRANDS CAROUSEL -->
      <div class="row">
        <div class="col text-center">
          <div class="owl-carousel brands-carousel-6">
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/bank-of-africa.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/cafe-javas.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/dfcu.jpeg" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/gtbank.jpg" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/Absa_Logo_1.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/kcb.jpg" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/OpportunityBank.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/roke-telkom.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/Standard_Chartered.png" alt="brand-logo"></a>
            </div>
          </div>
        </div>
      </div> <!-- END BRANDS CAROUSEL -->
    </div> <!-- End container -->
  </div>

  <!-- USSD SERVICES PAGE -->

  <!-- Section 1: Introduction to USSD -- >
  <section id="introduction" class="content-section division" style="background-color: #ffffff;">
    <div class="container">
      <div class="row align-items-center">
        <!- - Text Content -- >
        <div class="col-md-6">
          <div class="section-title mb-40">
            <h3 class="h2-xs">What is USSD?</h3>
            <p class="p-lg">Unstructured Supplementary Service Data (USSD) is a communication protocol used by GSM cellular telephones to communicate with the service provider's computers. It enables real-time, interactive communication between users and applications without the need for an internet connection, making it an essential tool for businesses in Africa to reach a wide audience efficiently.</p>
          </div>
        </div>
        <! -- Image/Video -- >
        <div class="col-md-6">
          <div class="section-img text-center">
            <img class="img-fluid" src="images/products/ussd-benefits.png" alt="USSD Services Image">
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <!-- Section 2: Benefits of USSD for Your Business -->
  <section id="benefits" class="content-section division py-5" style="background-color: #ffffff;">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h3 class="h2-xs">Why Choose USSD?</h3>
          <p class="p-lg">Discover the key benefits of integrating USSD services into your business operations.</p>
        </div>
      </div>
      <div class="row">
        <!-- Text Column -->
        <div class="col-md-6">
          <div class="benefit-list">
            <!-- Benefit 1 -->
            <div class="benefit-box text-left mb-40">
              <i class="fas fa-mobile-alt fa-3x text-success mb-3"></i>
              <h5 class="h5-md">Accessibility</h5>
              <p>USSD services are accessible on all mobile phones without the need for internet connectivity, ensuring wide reach across diverse populations.</p>
            </div>
            <!-- Benefit 2 -->
            <div class="benefit-box text-left mb-40">
              <i class="fas fa-dollar-sign fa-3x text-success mb-3"></i>
              <h5 class="h5-md">Cost-Effectiveness</h5>
              <p>Implementing USSD is highly cost-effective compared to other communication channels, allowing businesses to maximize their ROI.</p>
            </div>
            <!-- Benefit 3 -->
            <div class="benefit-box text-left mb-40">
              <i class="fas fa-sync fa-3x text-success mb-3"></i>
              <h5 class="h5-md">Real-Time Interaction</h5>
              <p>Engage with your customers in real-time, providing instant responses and facilitating immediate transactions.</p>
            </div>
            <!-- Benefit 4 -- >
            <div class="benefit-box text-left mb-40">
              <i class="fas fa-network-wired fa-3x text-success mb-3"></i>
              <h5 class="h5-md">Wide Mobile Compatibility</h5>
              <p>USSD works across all GSM networks, ensuring your services are compatible with a vast range of mobile devices.</p>
            </div>
            Benefit 5 - ->
            <div class="benefit-box text-left mb-40">
              <i class="fas fa-lock fa-3x text-success mb-3"></i>
              <h5 class="h5-md">Security</h5>
              <p>USSD transactions are secure and encrypted, protecting sensitive business and customer data.</p>
            </div>
             Benefit 6 - ->
            <div class="benefit-box text-left mb-40">
              <i class="fas fa-chart-line fa-3x text-success mb-3"></i>
              <h5 class="h5-md">Scalability</h5>
              <p>Scale your USSD services effortlessly as your business grows, handling increasing volumes of transactions and interactions.</p>
            </div>   --->
          </div>
        </div>

        <!-- Image Column -->
        <div class="col-md-6 text-center">
          <img src="images/ussd-benefits90.jpg" alt="USSD Benefits" class="img-fluid">
        </div>
      </div>
    </div>
  </section>

  <!-- Section 3: Key Features of Miint Africa’s USSD Solutions -->
  <section id="features" class="content-section division" style="background-color: #ffffff;">
    <div class="container py-5" style="background-color: #ffffff;">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h3 class="h2-xs">Our USSD Features</h3>
          <p class="p-lg">Explore the robust features that make Miint Africa’s USSD solutions stand out.</p>
        </div>
        <hr>
      </div>
      <div class="row">
        <!-- Feature 1 -->
        <div class="col-md-6 col-lg-4">
          <div class="feature-item text-center mb-40">
            <i class="fas fa-plug fa-3x text-success mb-3"></i>
            <h5 class="h5-md">Easy Integration</h5>
            <p>Seamlessly integrate our USSD services with your existing systems, minimizing downtime and ensuring smooth operations.</p>
          </div>
        </div>
        <!-- Feature 2 -->
        <div class="col-md-6 col-lg-4">
          <div class="feature-item text-center mb-40">
            <i class="fas fa-cogs fa-3x text-success mb-3"></i>
            <h5 class="h5-md">Customizable Solutions</h5>
            <p>Tailor our USSD services to fit your business needs, providing personalized experiences for your customers.</p>
          </div>
        </div>
        <!-- Feature 3 -->
        <div class="col-md-6 col-lg-4">
          <div class="feature-item text-center mb-40">
            <i class="fas fa-headset fa-3x text-success mb-3"></i>
            <h5 class="h5-md">24/7 Customer Support</h5>
            <p>Our dedicated support team is available around the clock to assist you with any queries or issues you may encounter.</p>
          </div>
        </div>
      </div>
    </div>
  </section>


 
</template>


 
<script>
/* eslint-disable */
export default {
  name: 'appUSSD',
  mounted() {
    $('.brands-carousel-6').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      autoplay: true,
      autoplayTimeout: 3000,
      responsive: {
        0: {
          items: 2
        },
        600: {
          items: 4
        },
        1000: {
          items: 6
        }
      }
    });

    $('.ussd-carousel').owlCarousel({
      loop: true,
      margin: 10,
      nav: false,
      autoplay: true,
      autoplayTimeout: 3000,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        1000: {
          items: 3
        }
      }
    });
  }
};
</script>
<style>
/* General Styles */
.hero-section {
    padding: 80px 0;
    background-color: #f9f9f9;
}

.brands-section {
    padding: 60px 0;
    background-color: #ffffff;
}

.brands-title p {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.brand-logo img {
    max-width: 150px; /* Adjust logo size */
    margin: 0 auto;
}

/* Carousel Styles */
.owl-carousel .item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px; /* Adjust spacing around logos */
}

.owl-carousel .item img {
    max-height: 80px; /* Maintain aspect ratio */
    transition: transform 0.3s; /* Animation effect */
}

.owl-carousel .item:hover img {
    transform: scale(1.1); /* Zoom effect on hover */
}

/* Content Section Styles */
.content-section {
    padding: 60px 0;
}

.section-title h3 {
    color: #065806;
}

.benefit-list .benefit-box {
    text-align: left;
    padding: 20px;
    border: 1px solid #eaeaea; /* Add border for visibility */
    border-radius: 5px;
    transition: box-shadow 0.3s;
}

.benefit-list .benefit-box:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Shadow effect on hover */
}

.ussd-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    color: #fff;
    opacity: 0;
    transition: opacity 0.3s;
}

.ussd-item {
    position: relative;
}

.ussd-item:hover .ussd-overlay {
    opacity: 1; /* Show overlay on hover */
}

/* Call to Action Styles */
#call-to-action {
    background-color: #065806;
    color: white;
    padding: 40px 0;
    text-align: center;
}

</style>