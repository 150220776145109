<template>
  <footer id="footer-4" class="footer division">
    <div class="container">
      <div class="row">
        <!-- Footer Company Info -->
        <div class="col-lg-3">
          <div class="footer-info mb-40">
            <img class="footer-logo" :src="logo" alt="footer-logo">
            <p style="font-size:14px; text-align: left;" class="mt-3">
              Founded in 2001, True African is akin to a mobile and online Financial Services Hub, the largest
              Financial Services Hub of its kind in the country. True African provides custom-tailored plug-and-play
              software solutions to address specific business needs.
            </p>
          </div>
        </div>

        <!-- About Section -->
        <div class="col-sm-6 col-md-3 col-lg-2 offset-xl-1">
          <div class="footer-links mb-40">
            <h6 class="h6-xl">About</h6>
            <ul class="foo-links text-secondary clearfix">
              <li><p class="p-md"><router-link to="#">About Us</router-link></p></li>
              <li><p class="p-md"><router-link to="#">Our Team</router-link></p></li>
              <li><p class="p-md"><router-link to="#">Careers</router-link></p></li>
            </ul>
          </div>
        </div>

        <!-- Contact Section -->
        <div class="col-sm-6 col-md-4 col-lg-3">
          <div class="footer-links mb-40">
            <h6 class="h6-xl">UGANDA OFFICE</h6>
            <div class="footer-contact">
              <div class="contact-item">Telephone: +256 393 230 380</div>
              <div class="contact-item">Mobile: +256 772 744289</div>
              <div class="contact-item">E-mail: <a href="mailto:info@trueafrican.com">info@trueafrican.com</a></div>
              <div class="contact-item">Address: True African (U) Ltd.</div>
              <div class="contact-item">Mpanga Close Bugolobi,</div>
              <div class="contact-item">P.O Box 71843 Kampala, Uganda</div>
            </div>
          </div>
        </div>

<!-- Our API Section -->
<div class="col-sm-6 col-md-6 col-lg-2 offset-xl-1">
  <div class="footer-links mb-40">
    <h6 class="h6-xl">Our APIs</h6>
    <ul class="foo-links text-secondary clearfix">
      <li><p class="p-md"><router-link to="/sms" @click="closeMobileMenuAndScroll">SMS</router-link></p></li>
      <li><p class="p-md"><router-link to="/collections" @click="closeMobileMenuAndScroll">Collections</router-link></p></li>
      <li><p class="p-md"><router-link to="/airtime" @click="closeMobileMenuAndScroll">Airtime</router-link></p></li>
      <li><p class="p-md"><router-link to="/kyc-validation" @click="closeMobileMenuAndScroll">KYC Validation</router-link></p></li>
      <li><p class="p-md"><router-link to="/ussd" @click="closeMobileMenuAndScroll">USSD ShortCode</router-link></p></li>
    </ul>
  </div>
</div>

      </div>

      <!-- Bottom Footer -->
      <div class="bottom-footer">
        <div class="row row-cols-1 row-cols-md-2 d-flex align-items-center">
          <div class="col">
            <div class="footer-copyright">
              <p>&copy; {{ currentYear }} Miint Africa. All Rights Reserved</p>
            </div>
          </div>
          <div class="col">
            <ul class="bottom-footer-list text-secondary text-end">
              <li><router-link to="#">Privacy Policy</router-link></li>
              <li><router-link to="#">Terms & Conditions</router-link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',

  data() {
    return {
      currentYear: new Date().getFullYear(), // Automatically fetch the current year
      logo: '/images/TA_logo.png'
    };
  },
};
</script>
<style scoped>
/* General Footer Styling */
.footer {
  padding: 20px 15px;
}

.footer .container {
  display: flex;
  flex-direction: column;
  text-align: left; /* Align all content inside the container to the left */
}

/* Section Margins */
.footer-info,
.footer-links {
  margin-bottom: 20px;
}

/* Footer Logo */
.footer-logo {
  max-width: 120px;
  display: block;
  margin-bottom: 10px; /* Add space below the logo */
}

/* Footer Text */
.footer-info p,
.footer-links p {
  font-size: 0.9rem;
  line-height: 1.4;
  text-align: left; /* Ensure all text inside the footer is left-aligned */
}

/* Footer Headings */
.footer h6 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left; /* Ensure all headings are left-aligned */
}

/* Footer Links */
.footer-links ul {
  padding: 0;
  list-style: none;
  text-align: left; /* Left-align the list items */
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  text-decoration: none;
  transition: all 0.3s ease;
}

.footer-links a:hover {
  text-decoration: underline; /* Underline links on hover */
}

/* Contact Section Items */
.footer-contact {
  display: flex;
  flex-direction: column;
  text-align: left; /* Align contact info to the left */
}

.contact-item {
  margin-bottom: 6px;
}

.contact-item a:hover {
  text-decoration: underline;
}

/* Bottom Footer */
.bottom-footer {
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid #ddd;
  margin-top: 20px;
}

.bottom-footer p {
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.bottom-footer .bottom-footer-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.bottom-footer .bottom-footer-list li {
  display: inline;
  margin: 0 10px;
}

/* Responsive Layout for smaller screens (720px) */
@media (max-width: 720px) {
  /* Stack Footer Sections */
  .footer .row {
    flex-direction: column;
    align-items: flex-start;
  }

  /* Adjust Columns for Contact Section */
  .footer .col-lg-3,
  .footer .col-sm-6,
  .footer .col-md-3,
  .footer .col-md-4 {
    flex: 1 1 100%;
    margin-bottom: 20px;
  }

  /* Footer Contact Adjustments */
  .footer-contact {
    width: 100%; /* Ensure full width for contact details */
  }

  /* Adjust Links */
  .footer-links ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-links li {
    margin: 5px 0;
  }

  /* Adjust Contact Details */
  .footer-contact .contact-item {
    font-size: 0.85rem; /* Smaller font size for mobile */
    margin-bottom: 8px; /* Reduce margin for better fit */
    word-wrap: break-word; /* Ensure long words don't break */
    white-space: normal; /* Allow wrapping in mobile */
  }

  /* Bottom Footer Links Centered */
  .bottom-footer .bottom-footer-list {
    display: flex;
    justify-content: center;
    gap: 12px;
  }

  /* Footer Logo - Make logo smaller on mobile */
  .footer-logo {
    max-width: 90px;
    margin-bottom: 15px;
  }
}
</style>
