// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import appHome from '@/views/appHome.vue';  
import appAbout from '@/components/appAbout.vue';
import appAirtime from '@/components/appAirtime.vue';  
import appCareers from '@/components/appCareers.vue';
import appCollections from '@/components/appCollections.vue';  
import appKYCValidation from '@/components/appKYCValidation.vue';  
import appPrices from '@/components/appPrices.vue';  
import appPrivacyPolicy from '@/components/PrivacyPolicy.vue';
import appTermsConditions from '@/components/TermsConditions.vue';
import appSMS from '@/components/appSMS.vue';  
import appTeam from '@/components/appTeam.vue';
import appUSSD from '@/components/appUSSD.vue';  
import appWhyMiint from '@/components/appWhyMiint.vue';  
import JoinWaitingList from '@/components/JoinWaitingList.vue';
import ThankYou from '@/components/ThankYou.vue';

const routes = [
  { path: '/', component: appHome, name: 'home' },
  { path: '/about', component: appAbout, name: 'about' },
  { path: '/airtime', component: appAirtime, name: 'airtime' },
  { path: '/careers', component: appCareers, name: 'careers' },
  { path: '/collections', component: appCollections, name: 'collections' },
  { path: '/kyc-validation', component: appKYCValidation, name: 'kyc-validation' },
  { path: '/prices', component: appPrices, name: 'prices' },
  { path: '/privacy-policy', component: appPrivacyPolicy, name: 'privacy-policy' },
  { path: '/terms-conditions', component: appTermsConditions, name: 'terms-conditions' },
  { path: '/sms', component: appSMS, name: 'sms' },
  { path: '/team', component: appTeam, name: 'team' },
  { path: '/ussd', component: appUSSD, name: 'ussd' },
  { path: '/why-miint', component: appWhyMiint, name: 'why-miint' },
  { path: '/join-waiting-list', component: JoinWaitingList, name: 'join-waiting-list' },
  { path: '/thank-you', component: ThankYou, name: 'thank-you' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
