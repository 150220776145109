// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';


// Importing CSS files
import './assets/css/bootstrap.min.css';
import './assets/css/flaticon.css';
import './assets/css/menu.css';
import './assets/css/dropdown-effects/fade-down.css';
import './assets/css/magnific-popup.css';
import './assets/css/owl.carousel.min.css';
import './assets/css/owl.theme.default.min.css';
import './assets/css/style.css';
import './assets/css/responsive.css';

// Uncomment if you want to include Google Fonts or Font Awesome
// import 'https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap'; // Google Font
// import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css'; // Font Awesome
// import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css'; // Font Awesome 6

// Importing JavaScript libraries
import './assets/js/menu.js'; 
 

// Create and mount the Vue app
createApp(App)
  .use(router)
  .mount('#app');
