<template>
  <div class="thank-you-container">
    <h1>Thank you for joining the waiting list!</h1>
    <p>Your submission has been received. We will notify you when there is an update.</p>
    <router-link to="/">Go back to Home</router-link>
  </div> <hr>
</template>

<script>
export default {
  name: 'ThankYou'
};
</script>

<style scoped>
.thank-you-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh; /* Ensure it takes full viewport height */
  text-align: center;
  padding: 20px;
}

.thank-you-container h1 {
  font-size: 2rem;
  color: #28a745;
  margin-bottom: 10px;
}

.thank-you-container p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.thank-you-container a {
  font-size: 1rem;
  color: #088f25;
  text-decoration: none;
}

.thank-you-container a:hover {
  text-decoration: underline;
}
</style>
