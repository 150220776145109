<template>
  <div id="hero-16" class="hero-section division" style="background-color: white; margin-top: 90px;">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-md-5 col-lg-6 order-last order-md-2">
          <div class="hero-16-img pc-25 text-center">
            <img class="img-fluid" src="images/q90.jpg" alt="hero-image" style="border-radius:10px;">
          </div>
        </div>
        <div class="col-md-7 col-lg-6 order-first order-md-2">
          <div class="hero-16-txt">
            <h2 class="h2-sm" style="color:#065806;">Select a plan suitable for your business</h2>
            <p class="p-xl" style="color: black;">Get a modern digital business account trusted by some of the largest
              brands in
              Africa. Make and
              receive payments with ease, simplify financial operations, and grow your business without any limits.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- BRANDS-1 -->
  <div id="brands-1" class="pb-100 brands-section py-5">
    <div class="container">
      <!-- BRANDS TITLE -->
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-9">
          <div class="brands-title mb-50">
            <h5 class="s-17">Trusted and used by some of the largest brands in Africa</h5>
          </div>
        </div>
      </div>

      <!-- BRANDS CAROUSEL -->
      <div class="row" style="background-color: white;">
        <div class="col text-center">
          <div class="owl-carousel brands-carousel-6">
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/bank-of-africa.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/cafe-javas.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/dfcu.jpeg" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/gtbank.jpg" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/Absa_Logo_1.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/kcb.jpg" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/OpportunityBank.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/roke-telkom.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/Standard_Chartered.png" alt="brand-logo"></a>
            </div>
          </div>
        </div>
      </div> <!-- END BRANDS CAROUSEL -->
    </div> <!-- End container -->
  </div> <!-- END BRANDS-1 -->

  <!-- DIVIDER LINE -->
  <hr class="divider">


  <!-- Section 3: Benefits -->
  <div class="row justify-content-center" style="background-color: #ffffff; margin-top:30px;">
    <div class="col-lg-8">
      <div class="brands-title text-center">
        <h2 class="p-xl">Compare Our Plans</h2>
        <p class="p-xl">
          All your company's payment and finance needs in a single platform
        </p>
      </div>
    </div>
  </div>

  <div id="benefits" class="content-section" style="background-color: #ffffff; margin-top:30px;">
    <div class="container">
      <div class="row align-items-center">
        <!-- Table -->
        <h5 class="s-24 w-700">Prepaid Bulk SMS</h5>
        <div class="table-responsive mb-50">
          <table class="table text-center">
            <thead>
              <tr>
                <th style="width: 22%;"></th>
                <th style="width: 22%;">Basic UGX<br> <span
                    style="font-family: Arial, sans-serif; font-weight: lighter;">0 - 499,999</span></th>
                <th style="width: 22%;">Plus plan UGX<br><span
                    style="font-family: Arial, sans-serif; font-weight: lighter;">500,000-2,499,999</span></th>
                <th style="width: 22%;">Premium plan UGX<br><span
                    style="font-family: Arial, sans-serif; font-weight: lighter;">2,500,000 - 9,999,999</span>
                </th>
                <th style="width: 42%;">Maximum plan UGX<br><span
                    style="font-family: Arial, sans-serif; font-weight: lighter;">10,000,000 + </span>
                </th>
                
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" class="text-start">MTN (Local Traffic)</th>
                <td class="color--black">UGX 27</td>
                <td class="color--black">UGX 25</td>
                <td class="color--black">UGX 22</td>
                <td class="color--black">UGX 19</td>
              </tr>
              <tr>
                <th scope="row" class="text-start">Airtel (Local Traffic)</th>
                <td class="color--black">UGX 25</td>
                <td class="color--black">UGX 23</td>
                <td class="color--black">UGX 20</td>
                <td class="color--black">UGX 17</td>
              </tr>
              <tr>
                <th scope="row" class="text-start">Others Telcos</th>
                <td class="color--black">UGX 35</td>
                <td class="color--black">UGX 35</td>
                <td class="color--black">UGX 30</td>
                <td class="color--black">UGX 25</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h5 class="s-24 w-700">Accept Payments</h5>
        <div class="table-responsive mb-50">
          <table class="table text-center">
            <thead>
              <tr>
                <th style="width: 34%;"></th>
                <th style="width: 22%;">Per Transaction</th>
                <th style="width: 22%;"></th>
                <th style="width: 22%;"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" class="text-start">Mobile Money</th>
                <td class="color--black"></td>
                <td class="color--black">3%</td>
                <td class="color--black"> </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h5 class="s-24 w-700">Shared USSD</h5>
        <p></p>
        <div class="table-responsive mb-50">
          <table class="table text-center">
            <thead>
              <tr>
                <th style="width: 8%;">Type</th>
                <th style="width: 22%;">Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" class="text-start">Set Up Cost</th>
                <td class="color--black">USD 1000</td>
              </tr>
              <tr>
                <th scope="row" class="text-start">Monthly Maintenance</th>
                <td class="color--black">UGX 600,000</td>
              </tr>
              <tr>
                <th scope="row" class="text-start">Annual</th>
                <td class="color--black">USD 2000</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: 'appPrices',
  mounted() {
    $('.brands-carousel-6').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      autoplay: true,
      autoplayTimeout: 3000,
      responsive: {
        0: {
          items: 2
        },
        600: {
          items: 4
        },
        1000: {
          items: 6
        }
      }
    });

    $('.ussd-carousel').owlCarousel({
      loop: true,
      margin: 10,
      nav: false,
      autoplay: true,
      autoplayTimeout: 3000,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        1000: {
          items: 3
        }
      }
    });
  }
};
</script>
